<template>
    <zw-sidebar @shown="shown" :title="modalLabel">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.parent_id"
                                         :options="getCategoryOptions() | optionsVL"
                                         name="parent"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Category-modal',
    mixins: [commonSave],
    data() {
        return {
            modalLabel: this.$t('settings.categories.title'),
            loading: true,
            payload: {},
            callback: null,
            matchKeys: ['value'],
            defaultForm: {
                name: '',
                value: '',
            },
            form: {},
            labelPrefix: 'settings.categories.label.',
        }
    },
    methods: {
        ...mapGetters('Category', ['getCategory', 'getCategoryOptions']),
        ...mapGetters('Attributes', ['getAttributesTree']),

        shown() {
            const category = this.$store.dispatch('Category/fetchCategory', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions', 1);
            const attributes = this.$store.dispatch('Attributes/fetchAttributesTree');


            Promise.all([category, categories, attributes])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getCategory()))
                    }

                    if (!this.form.type) {
                        this.form.type = this.payload.type
                    } else {
                        this.modalLabel = this.$t('settings.categories.title_sub')
                    }

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Category/saveCategory', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>